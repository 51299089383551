<template>
  <div class="browse-tab">
    <ui-button
      v-for="(ticker, i) in tickersStore.whiteList"
      :key="i"
      :type="chartStore.ticker === ticker.symbol ? 1 : 2"
      size="small"
      @click="tabClickHandler(ticker)"
    >
      {{ ticker.baseAsset }}
    </ui-button>
  </div>
</template>

<script>

import UiButton from '../ui/UiButton'
import { useTickersStore } from '../store/tickers'
import { useChartStore } from '../store/chart'

const tickersStore = useTickersStore()
const chartStore = useChartStore()

export default {
  components: {
    UiButton,
  },

  data() {
    return {
      tickersStore,
      chartStore,
    }
  },

  methods: {
    tabClickHandler(ticker) {
      chartStore.setBrokerData({
        ticker: ticker.symbol,
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/global.styl'

.browse-tab
  display flex
  flex-wrap wrap
  gap 12px

</style>
